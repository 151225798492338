@media all and (min-width: 480px) {
  body {
      /* background-color: rgb(246, 239, 207); */
      background-color: white;
      padding: 0 60px;
  }

  .Push {
    padding: 60px 0;
  }

  .Push form {
    margin: 0 auto;
    max-width: 320px;
  }

  .backgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./Pictures/backgroundimage.jpg');
    text-align: center;
  }

  .my-nav-link {
    font-size: 20px;
  }

  .my-nav-link::after {
    content: "";
    display: block;
    height: 2px;
    width: 0;
    background-color: #383737;
    transition: width 0.3s;
  }
  
  .my-nav-link:hover::after {
    width: 100%;
  }

  .my-navbar {
    height: 50px;
  }

  .settings-button-container {
    position: relative;
  }
  
  .settings-button {
    border: none;
    background: none;
    color: black;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .settings-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 0.5em;
  }
  

  .settings-dropdown button {
    display: block;
    width: 100%;
    border: none;
    background: none;
    color: #333;
    padding: 0.5em 1em;
    text-align: left;
    cursor: pointer;
  }
  
  .settings-dropdown button:hover {
    background-color: #f5f5f5;
  }

}