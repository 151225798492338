.App {
  background-color: rgba(255, 69, 0, 0.849);
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(246, 239, 207, 0.849); */
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.90);
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.error-login{
  text-align: center;
  color: rgb(255, 0, 0);
  visibility: hidden;
}

.btn-submit {
  /* background-color: rgb(249, 210, 95); */
  background-color: rgb(249, 185, 95);
  font-size: 16px;
  padding: 12px 28px;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  border: 2px solid rgb(249, 185, 95);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  width: 320px;
}

.btn-submit:hover {
  background-color: #ffffff; 
  color: rgb(255, 69, 0);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.extra-space {
  margin-left: .90rem; /* 4px */
}